// Battle events from ActionCable
export const CREATED_BATTLE = "created_battle"
export const PLAYER_JOINED = "player_joined"
export const PLAYER_JOINED_FULL = "player_joined_full"
export const PLAYER_READY = "player_ready"
export const PLAYER_UNREADY = "player_unready"
export const PLAYERS_ALL_READY = "players_all_ready"
export const BATTLEFILE_GENERATED = "battlefile_generated"
export const PLAYER_LEFT = "player_left"
export const REMOVE_BATTLE = "removed_battle"
export const BATTLE_FINALIZED = "battle_finalized"
export const PLAYER_ABANDONED = "player_abandoned"
export const PLAYERS_ALL_ABANDONED = "players_all_abandoned"
export const ELO_UPDATED = "elo_updated"
export const SIZE_UPDATED = "size_updated"
