import React from 'react'

export const SteamLoginButton = () => {
  /**
   * The Omniauth gem for Rails requires a valid CSRF Authenticity Token (generated by Rails on page load) before it
   * will validate the request to go to the third party. This is a hack to include that CSRF Token, which already exists
   * on the page, as part of the form submission to Omniauth
   */
  const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  return (
    <form method="post" action="/players/auth/steam" data-turbo="false" style={{height: '66px'}}>
      <input type="image" src="https://community.akamai.steamstatic.com/public/images/signinthroughsteam/sits_02.png"
             alt="Log in to Steam" />
      <input type="hidden" name="authenticity_token" value={csrfToken} />
    </form>

  )
}
