import burning_bombardment from '../../../assets/images/doctrines/panzer_elite/offmaps/burning_bombardment.png'
import butterfly_bombs_offmap from '../../../assets/images/doctrines/panzer_elite/offmaps/butterfly_bombs_offmap.png'
import counter_offensive from '../../../assets/images/doctrines/panzer_elite/offmaps/counter_offensive.png'
import declare_festung from '../../../assets/images/doctrines/panzer_elite/unlocks/scorched_earth/declare_festung_placeholder.png'
import luftwaffe_dive_bomber from '../../../assets/images/doctrines/panzer_elite/offmaps/luftwaffe_dive_bomber.png'
import overextended_offensive from '../../../assets/images/doctrines/panzer_elite/unlocks/scorched_earth/overextended_offensive.png'
import sector_artillery from '../../../assets/images/doctrines/panzer_elite/unlocks/scorched_earth/sector_artillery.png'
import sector_disabled from '../../../assets/images/doctrines/panzer_elite/offmaps/sector_disabled.png'
import stuka_dive from '../../../assets/images/doctrines/panzer_elite/offmaps/stuka_dive.png'

export const BURNING_BOMBARDMENT = 'burning_bombardment'
export const BUTTERFLY_BOMBS_OFFMAP = 'butterfly_bombs_offmap'
export const COUNTER_OFFENSIVE = 'counter_offensive'
export const DECLARE_FESTUNG = 'declare_festung'
export const LUFTWAFFE_DIVE_BOMBER = 'luftwaffe_dive_bomber'
export const OVEREXTENDED_OFFENSIVE = 'overextended_offensive'
export const SECTOR_ARTILLERY = 'sector_artillery'
export const SECTOR_DISABLED = 'sector_disabled'
export const STUKA_DIVE = 'stuka_dive'

export const offmapImageMapping = {
  [BURNING_BOMBARDMENT]: burning_bombardment,
  [BUTTERFLY_BOMBS_OFFMAP]: butterfly_bombs_offmap,
  [COUNTER_OFFENSIVE]: counter_offensive,
  [DECLARE_FESTUNG]: declare_festung,
  [LUFTWAFFE_DIVE_BOMBER]: luftwaffe_dive_bomber,
  [OVEREXTENDED_OFFENSIVE]: overextended_offensive,
  [SECTOR_ARTILLERY]: sector_artillery,
  [SECTOR_DISABLED]: sector_disabled,
  [STUKA_DIVE]: stuka_dive,
}
